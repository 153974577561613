import payload_plugin_qLmFnukI99 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import performance_client_OXWUsvTdFu from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/performance.client.js";
import plugin_adVF2uRka6 from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_hBLWEPkA3b from "/vercel/path0/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import lcp_4na2qKKGQF from "/vercel/path0/plugins/optional/lcp.js";
import _1_polyfills_CZHYsaWWxY from "/vercel/path0/plugins/1.polyfills.ts";
import _2_storefront_B5zQ48lkkd from "/vercel/path0/plugins/2.storefront.ts";
import _3_initRepositories_dxHo2dotEv from "/vercel/path0/plugins/3.initRepositories.ts";
import _4_storyblok_GNobFfD2ut from "/vercel/path0/plugins/4.storyblok.ts";
import _7_init_client_99CSkKh1RH from "/vercel/path0/plugins/7.init.client.ts";
import _8_userId_client_WaNBDtc0tE from "/vercel/path0/plugins/8.userId.client.ts";
import _9_vue_bind_once_V1hGBNmkIt from "/vercel/path0/plugins/9.vue-bind-once.ts";
import kindly_client_ENiD8vJPbP from "/vercel/path0/plugins/kindly.client.ts";
import price_8YPXKugP1w from "/vercel/path0/plugins/price.ts";
import routing_4D4PMB3cQ6 from "/vercel/path0/plugins/routing.ts";
import set_cookies_fallback_LveDmNlJ1C from "/vercel/path0/plugins/set-cookies-fallback.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  performance_client_OXWUsvTdFu,
  plugin_adVF2uRka6,
  plugin_hBLWEPkA3b,
  plugin_tbFNToZNim,
  lcp_4na2qKKGQF,
  _1_polyfills_CZHYsaWWxY,
  _2_storefront_B5zQ48lkkd,
  _3_initRepositories_dxHo2dotEv,
  _4_storyblok_GNobFfD2ut,
  _7_init_client_99CSkKh1RH,
  _8_userId_client_WaNBDtc0tE,
  _9_vue_bind_once_V1hGBNmkIt,
  kindly_client_ENiD8vJPbP,
  price_8YPXKugP1w,
  routing_4D4PMB3cQ6,
  set_cookies_fallback_LveDmNlJ1C
]